import { Card, Col, Row } from "react-bootstrap";

import useToken from "../../../utils/hooks/useToken";
import gold from "../assets/img/gold.png";
import silver from "../assets/img/silver.png";
import bronze from "../assets/img/bronze.png";
import medal from "../assets/img/medal.png";
import { useEffect, useState } from "react";
import { SellerInfo } from "../interfaces/dashboard";
import { getSellerInfo } from "../services/dashboard";
import { ConfigData } from "../../Config/interfaces/config";
import { getConfig } from "../../Config/services/config";
import { baseURL } from "../../../utils/axios";

const Header = () => {
  const { user, isAdmin } = useToken();
  const [sellerInfo, setSellerInfo] = useState<SellerInfo>();
  const [rankPosition, setRankPosition] = useState<number>(0);
  const [theConfig, setTheConfig] = useState<ConfigData>();

  useEffect(() => {
    if (!isAdmin) {
      getSellerInfo().then((response) => {
        setSellerInfo(response);
        setRankPosition(response?.position ? Number(response.position) + 1 : 0);
      });
      getConfig().then((response) => setTheConfig(response));
    }
  }, [user]);

  return (
    <>
      <div className="mb-3">
        <h3>Dashboard</h3>
      </div>
      <Row>
        <Col md="6" className="d-flex">
          <Card className="illustration flex-fill">
            <Card.Body className="p-0 d-flex flex-fill">
              <Row className="w-100 g-0">
                {!isAdmin && (
                  <Col xs="4" className="align-self-center text-center">
                    <div className="py-3">
                      <img
                        // src={
                        //   rankPosition === 1
                        //     ? gold
                        //     : rankPosition === 2
                        //     ? silver
                        //     : rankPosition === 3
                        //     ? bronze
                        //     : medal
                        // }
                        src={medal}
                        alt="Troféu"
                        className="img-fluid illustration-img"
                      />
                    </div>
                  </Col>
                )}
                <Col xs={isAdmin ? 12 : 8} className="align-self-center">
                  <div className="illustration-text p-3 m-1">
                    <h4 className="illustration-text">
                      Bem-vindo(a) de volta, {user?.user.name}!
                    </h4>
                    <p className="mb-0">
                      {/* {isAdmin
                        ? "Administre seu sistema de pontos"
                        : "Você tem " +
                          sellerInfo?.loyalty_points_total +
                          " pontos. Sua posição no ranking é #" +
                          rankPosition} */}
                      {isAdmin
                        ? "Administre seu sistema de pontos"
                        : "AVISO: O ranking estará indisponível até a conclusão do envio das notas fiscais. Cadastre todas as suas vendas até o dia 6 de janeiro, às 23h59."}
                    </p>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        {!isAdmin && !!theConfig && !!theConfig.banner && (
          <Col md="6" className="d-flex">
            <Card className="illustration-app flex-fill">
              <Card.Body className="p-0 d-flex flex-fill">
                {theConfig.banner_link ? (
                  <a
                    href={theConfig.banner_link}
                    target="_blank"
                    className="w-100 d-block"
                  >
                    <img
                      src={baseURL + "/uploads/banner/" + theConfig.banner}
                      alt="fundo"
                      className="illustration-bg w-100"
                    />
                  </a>
                ) : (
                  <img
                    src={baseURL + "/uploads/banner/" + theConfig.banner}
                    alt="fundo"
                    className="illustration-bg w-100"
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default Header;
